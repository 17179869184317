import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69')
];

export const server_loads = [0,13];

export const dictionary = {
		"/(shop)": [~14,[2],[3]],
		"/admin": [~49,[13]],
		"/admin/blogs": [~50,[13]],
		"/admin/blogs/edit-article": [~51,[13]],
		"/admin/brands": [~52,[13]],
		"/admin/brands/[id]": [~53,[13]],
		"/admin/categories": [~54,[13]],
		"/admin/categories/edit-category": [~55,[13]],
		"/admin/dashboard": [~56,[13]],
		"/admin/images": [~57,[13]],
		"/admin/offers": [~58,[13]],
		"/admin/offers/[id]": [~59,[13]],
		"/admin/orders": [~60,[13]],
		"/admin/products": [~61,[13]],
		"/admin/products/edit-product": [~62,[13]],
		"/admin/settings": [63,[13]],
		"/admin/settings/users": [64,[13]],
		"/admin/tags": [~65,[13]],
		"/admin/tags/[id]": [~66,[13]],
		"/admin/users": [~67,[13]],
		"/admin/users/edit-user": [~68,[13]],
		"/(shop)/articles": [~19,[2],[3]],
		"/(shop)/articles/[id]": [~20,[2],[3]],
		"/(shop)/brands": [~21,[2],[3]],
		"/(shop)/brands/contactus": [23,[2],[3]],
		"/(shop)/brands/[id]/[name]": [~22,[2],[3,4]],
		"/(shop)/cart": [~26,[2],[3,6]],
		"/(shop)/checkout": [~27,[2],[3,7]],
		"/(shop)/companies": [~28,[2],[3]],
		"/(shop)/c": [~24,[2],[3,5]],
		"/(shop)/c/[id]/[[uri]]": [~25,[2],[3,5]],
		"/(shop)/(account)/login": [~15,[2],[3]],
		"/(shop)/(account)/logout": [~16,[2],[3]],
		"/(shop)/(account)/myaccount": [~17,[2],[3]],
		"/(shop)/order-complete": [~29,[2],[3]],
		"/(shop)/pages/catalog": [32,[2],[3]],
		"/(shop)/pages/contactus": [~33,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2015": [34,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2017": [35,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2018": [36,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2019": [37,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2020": [38,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2022": [39,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2023": [40,[2],[3]],
		"/(shop)/pages/cronus-in-artoza-2024": [41,[2],[3]],
		"/(shop)/pages/delegations": [~42,[2],[3,10]],
		"/(shop)/pages/location": [43,[2],[3]],
		"/(shop)/pages/refurbished": [~44,[2],[3,11]],
		"/(shop)/p": [~30,[2],[3,8]],
		"/(shop)/p/[id]/[[uri]]": [~31,[2],[3,8,9]],
		"/(shop)/search": [~46,[2],[3]],
		"/(shop)/search/[key]": [~47,[2],[3,12]],
		"/sentry-example": [69],
		"/(shop)/(account)/signup": [~18,[2],[3]],
		"/(shop)/s/[id]/[url]": [~45,[2],[3]],
		"/(shop)/topcategories": [48,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';